<template>
  <v-sheet :set="hover = false"
           class="wsRoundedHalf pointer"
           @dragover.prevent="dropzoneHover=true"
           @dragleave.prevent="dropzoneHover=false"
           @drop.prevent="drop"
           style="transition: all 0.3s ease; position: relative"
           :color="!src ? '#33333366' : 'transparent' "
           :min-height="height ? height : 200"
           :height="height ? height : null "
           :style="`background-image: url('${background}'); background-size: cover;`"
           @click="selectFile">

    <input style="display: none" id="fileInput" type="file"  @change="fileBrowsed">

    <v-sheet :color="dropzoneHover ? '#000000cc' : '#00000000' "
             style="position:absolute; width: 100%; height: 100% !important;"
             class="d-flex align-center justify-center wsRoundedHalf ">

      <v-sheet :color="background ? '#ffffffdd' : 'transparent'"
               :class="background ? 'text-center pa-6 wsRoundedHalf' : ''" v-if="!dropzoneHover">
        <v-fade-transition mode="out-in">
          <div v-if="!$store.state.LOADING && !src " >
            <div v-if="!height || height > 70" class="d-flex justify-center">
              <v-icon x-large>mdi-tray-arrow-down</v-icon>
            </div>
            <h5 v-if="!customText" class=" text-center">Оберіть файл або перетягніть сюди</h5>
            <slot v-else></slot>
          </div>
          <v-progress-circular v-if="$store.state.LOADING" indeterminate />
        </v-fade-transition>
      </v-sheet>



    </v-sheet>

    <div v-if="src" style="position:absolute; width: 100%; height: 100%">
        <v-img height="100%" :style="`border-radius: ${radius}px`" :position="position" width="100%" transition="xxx" :src="src" />
    </div>






  </v-sheet>
</template>

<script>

export default {
  name: "wsImageUpload",
  props : ['src','type','customText','background','position','click','radius','height'],
  data() {
    return {
      dropzoneHover : false
    }
  },
  watch : {
    radius(value) {
      if ( value === null || value === undefined ) {
        this.radius = 0
      }
    }
  },
  methods : {
    drop(e) {
      this.dropzoneHover=false
      this.$emit('upload',e.dataTransfer.files[0])
    },
    selectFile() {
      if ( this.click === false)  { return }
      document.getElementById('fileInput').click()
    },
    fileBrowsed(e) {
      this.$emit('upload',e.target.files[0])
    }
  }
}
</script>

<style scoped>

</style>